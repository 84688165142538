<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name', 'code', 'preferred']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" style="width: 30%"></Column>
            <Column field="code" header="LOCODE" style="width: 30%"></Column>
            <Column field="preferred" :header="$t('overview.preferred_port')" style="min-width:16rem">
                <template #body="slotProps">
                    <i v-if='slotProps.data.preferred' class='fas fa-check text-success' />
                </template>
            </Column>
        </template>

        <template #form>
            <PortForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import PortForm from '@/components/port/PortForm.vue';
import portService from '@/services/PortService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        PortForm
    },
    data() {
        return {
            item: {},
            itemService: portService,
            itemFormRef: null
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>
