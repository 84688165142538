<template v-if="formActive">
    <FormItem id="name" :label="$t('overview.name')" v-model="port.name" :required="true" />
    <FormItem id="code" :label="`UN/Locode (3 characters)`" v-model="port.code" :required="true" sizeItem=2 />
    <FormItem id="country_id" :label="$t('navigation.countries')" type='select' :options='countries' v-model="port.country_id" :required="true" />
    <FormItem id="trepv" :label="$t('overview.representative', 2)" v-model="port.representatives" type="livesearch-list" :options="representatives" :content="port.representatives.name" />
    <FormItem id="short_sea_region_id" :label="`Short Sea region`" type='select' :options='getShortSeaRegions' v-model="port.short_sea_region_id" />
    <FormItem id="deep_sea_region_id" :label="`Deep Sea region`" type='select' :options='getDeepSeaRegions' v-model="port.deep_sea_region_id" :required="true" />
    <FormItem id="preferred" :label="`Preferred Transshipment Port`" type='checkbox' v-model="port.preferred" :style="{width: '35%'}" />
</template>

<script>
    import dataStore from '@/store/data'
    import regionService from '@/services/RegionService'
    import representativeService from '@/services/RepresentativeService'
    
    export default {
        name: 'PortForm',
        props: ['itemData'],
        data() {
            return {
                countries: [],
                port: { representatives: [] },
                representatives: [],
                terminals: [],
                regions: [],

                submitted: false,
                invalid: {},
                dataItemsLoaded: 0,
                dataItemsToLoad: 2,
                isEditing: false,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.port = val    
            },
        },
        computed: {
            getShortSeaRegions: function () {
                return this.regions.filter(region => region.region_type_id == 1);
            },
            getDeepSeaRegions: function () {
                return this.regions.filter(region => region.region_type_id == 2);
            }
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                for(let field of required) {
                    let fieldname = field.id;
                    
                    if(!this.port[fieldname]) {
                        this.invalid[fieldname] = true;
                        valid = false;
                    }
                }

                return valid;
            },
            getData() {
                return this.port;
            },
            setData(data) {
                this.port = data
            },
            reset() {
                this.port = {
                    'name': null,
                    'code': null,
                    'preferred': 0,
                    'country_id': null,
                    'representatives': [],
                    'short_sea_region_id': null,
                    'deep_sea_region_id': null
                }
                this.loading = false
                this.invalid = {}
                this.loadData();
            },

            getCountries() {
                this.countries = dataStore.getters.getCountryNames;
            },
            getRegions() {
                regionService.index('').then(response => {
                    this.regions = response.data
                    this.dataItemsLoaded++;
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getRelations() {
                representativeService.index().then(response => {
                    this.representatives = response.data
                    this.dataItemsLoaded++;
                }).catch(error => {
                    console.log('error', error)
                })
            },
            loadData(){
                this.getCountries()
                this.getRegions()
                this.getRelations()
            }
        },
        mounted() {
            //this.reset()
        }
    }

</script>
